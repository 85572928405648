<template>
  <div class="h-screen w-full bg-gray-100 flex items-center">
    <div class="flex flex-col w-full items-center">
      <div class="text-5xl font-dark font-bold">Reservation Cancelled</div>
      <p class="mt-2 text-2xl md:text-3xl font-light leading-normal">
        You have cancelled your unit reservation.
      </p>
      <p class="max-w-3xl mt-5 text-center">
        If you did this by accident, simply click below to navigate back to the
        price list to select your preferred unit again and continue to the
        reservation page.
      </p>

      <a
        href="/"
        class="block w-72 mt-8 p-4 text-sm text-center font-semibold leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none uppercase focus:shadow-outline-blue bg-primary active:bg-secondary hover:bg-secondary"
        >Back to homepage</a
      >
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { unitsCollection, dealsCollection } from "../../firebase.js";
import isNil from "lodash/isEmpty";

export default {
  name: "Cancel",
  data: () => ({
    unit: {},
  }),
  created() {
    const unitID = this.$route.params.id;

    var reservationCancelToken = null;

    if (!isNil(this.$route.query.token) && !isNil(unitID)) {
      reservationCancelToken = this.$route.query.token;
    } else {
      this.router.replace({ name: "home" });
    }


    console.log(reservationCancelToken);
    let timestamp = firebase.firestore.Timestamp.now();

    // TODO: send email if url id/token is incorrect
    unitsCollection
      .doc(unitID)
      .get()
      .then((doc) => {
        if (isNil(doc.data())) {
          this.$router.replace({ name: "home" });
        }

        const unitID = doc.id;
        const unitReservationCancelToken = doc.data().reservationCancelToken;

        if (isNil(unitReservationCancelToken)) {
          this.$router.replace({ name: "home" });
        }

        if (unitReservationCancelToken !== reservationCancelToken) {
          this.$router.replace({ name: "home" });
        } else {
          return unitID;
        }
      })
      .then((unitID) => {
        const updateData = {
          status: "available",
          modified: timestamp,
        };
        unitsCollection.doc(unitID).update(updateData);

        const updateDealData = {
          status: "available",
          reservationCancelledTimestamp: timestamp,
        };
        dealsCollection
          .where("unitId", "==", unitID)
          .limit(1)
          .get()
          .then((query) => {
            if (query.docs && query.docs.length > 0) {
              const deal = query.docs[0];
              deal.ref.update(updateDealData);
            }
          });
      })
      .catch((error) => {
        console.log(error.message);
        this.$router.replace({ name: "home" });
      });
  },
};
</script>
